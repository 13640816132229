import {SET_CATEGORIES, ADD_CATEGORY} from "../actions/actionTypes"

const initialState ={
    categories:[]
    //expiryDate: null
}
const reducer =(state = initialState, action) =>{
    switch(action.type){
        case "SET_CATEGORIES":
            return action.item
        case "ADD_CATEGORY":
            return {...state}    
        default:
            return state;
    }
}
export default reducer;