//import AsyncStorage from '@react-native-community/async-storage';
//import auth from '@react-native-firebase/auth';
//import database from '@react-native-firebase/database';
import axios from "axios";
import {SET_BOOK} from "../actions/actionTypes"

//const UUID = require("uuid-v4");



export const getBook = (id) => {
    return((dispatch)=>{
        //console.log("b")
        const promise = new Promise(async(resolve,reject)=>{
            try {
                const book = await axios.get(global.backend+'books/'+id)
                //console.log(book.data)
                if(Object.keys(book.data).length!==0){
                    dispatch(setBook(book.data[0]));
                //console.log(books)
                resolve(book);
                }
            } catch(e) {
                console.log(e)
            }
        })
        return promise
        .then(val=>{
            if(val){
                //dispatch(setUser(val))
                //console.log(val)
                return val
                //return (val)

            }
            
        })

    })
}

export const getBooksAccess = (id) => {
    return((dispatch)=>{
        //console.log("b")
        const promise = new Promise(async(resolve,reject)=>{
            try {
                const book = await axios.get(global.backend+'access/'+id)
                //console.log(book.data)
                if(Object.keys(book.data).length!==0){
                    dispatch(setBook(book.data));
                //console.log(book)
                resolve(book);
                }
            } catch(e) {
                console.log(e)
            }
        })
        return promise
        .then(val=>{
            if(val){
                //dispatch(setBook(val))
                //console.log(val)
                return val
                //return (val)

            }
            
        })

    })
}

export const createBook = async (data) => {
    return((dispatch)=>{
        //console.log("b")
        const promise = new Promise(async(resolve,reject)=>{
            try {
                const response = await axios.post(global.backend+'books', {
                    name: data.name,
                    desc: data.desc,
                    role:1,
                    status:1,
                    user_id:data.user_id, 
                    
                })
                console.log(response)
            } catch(e) {
                console.log(e)
            }
        })
        return promise
        .then(val=>{
            if(val){
                dispatch(getBook(data.id))
                //console.log(val)
                return val
                //return (val)

            }
            
        })

    })
}

export const updateBook = (data) => {
    return((dispatch)=>{
        //console.log(data)
        const promise = new Promise(async(resolve,reject)=>{
            try {
                const response = await axios.post(global.backend+'books/update/'+data.bookId, {
                    name: data.name,
                    desc: data.desc,
                })
                if(response.status=="200"||response.status=="201"){
                    //props.handleClose()
                    resolve(true)
                }else{
                    resolve(false)
                }
                }catch(e){
                    console.log(e)
                }
        })
        return promise
        .then(val=>{
            if(val){
                dispatch(getBooksAccess(data.user_id))
                //console.log(val)
                return val
            }
        })
    })
}

export const updateBookMonth = async (data) => {
    return((dispatch)=>{
        //console.log("b")
        const promise = new Promise(async(resolve,reject)=>{
            try {
                const response = await axios.post(global.backend+'books/update/'+data.id, {
                    active_month: data.month,
                })
                console.log(response)
                //console.log(response.data.messages['bookId'])
                //localStorage.setItem("bookId",this.props.id)
                //this.setState({navigate:"/"})
            
            } catch(e) {
                console.log(e)
            }
        })
        return promise
        .then(val=>{
            if(val){
                dispatch(getBook(data.id))
                //console.log(val)
                return val
                //return (val)

            }
            
        })

    })
}


export const updateUser = async (user) => {
    //e.preventDefault();
    let response = await axios.post(global.backend+'users/'+this.state.action+'/'+this.state.user_id, {
        user_id: this.data.user_id,
        email: this.data.user_email,
        name: this.state.name,
        phone: this.state.phone,
        dob: this.formatDate(this.state.dob),
        profession: this.state.profession,
        sex: this.state.sex,
        location: this.state.location,
        marriage: this.state.marriage,
        smoking: this.state.smoking,
        bio: this.state.bio
    
    })
    this.setState({submitted:true})
    //console.log(response)
    //navigate("/")
}

export const setBook = (item) => {
    //AsyncStorage.setItem("grow:auth:user",user.uid);
    //console.log(user)
    //addUser(user)
    //console.log(item)
    return{
        type: SET_BOOK,
        item: item,
    }
}