import axios from "axios";
import React, { useState, useEffect, Component } from 'react'
import { Button, Col, Container, ListGroup, Row, Badge, Modal, Form, FormGroup, FormLabel } from "react-bootstrap";
import { Navigate} from 'react-router-dom';
import CurrencyInput,{ formatValue } from 'react-currency-input-field';
import {connect} from 'react-redux';
import {getAssets, createAsset, updateAsset} from '../app/actions/index'
//import {CreateAssetForm} from './createAsset'

class Assets extends Component{
    handleClose = () => this.setState({data:'', show:false, edit:false});
    handleShow = () => this.setState({show:true});
    //navigate = useNavigate()
    constructor(props){
        super(props);
		this.state ={
			show: false,
            data:'',
            edit:false
		}
    }
    componentDidMount(){
        /*
        if(typeof localStorage.bookId!=="undefined"){
            var bookId = JSON.parse(localStorage.bookId);
        }
        */
        //this.props.getAssets(bookId)
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }

    ListAssets = () =>{
        //console.log(this.props.assets)
        try{
        var assets = this.props.assets
        var asetLancar = assets.filter(asset => (asset.type ==='1' && asset.category ==='1'))
        var asetGuna = assets.filter(asset => (asset.type ==='1' && asset.category ==='2'))
        var asetInvestasi = assets.filter(asset => (asset.type ==='1' && asset.category ==='3'))
        var kewajibanPendek = assets.filter(asset => (asset.type ==='2' && asset.category ==='4'))
        var kewajibanPanjang = assets.filter(asset => (asset.type ==='2' && asset.category ==='5'))
            
        //console.log(asetLancar)
        return (
            <Row>
                <Col md={6}>
                    <Row>
                        <Col>
                            <h1>Aset Lancar</h1>
                            <ListGroup as="ol" numbered>
                                {asetLancar.map((item) => (
                                <ListGroup.Item 
                                key={item.asset_id} 
                                variant={item.active=="0" && "secondary"} 
                                action 
                                className="d-flex justify-content-between align-items-start" 
                                onClick={(key)=>{this.editData(item)}}
                                >
                                    <div className="ms-2 me-auto">
                                    <div className="fw-bold">{item.name}</div>
                                    {item.desc}
                                    </div>
                                    <Badge bg="primary" pill>
                                    {formatValue({
                value: item.amount,
                intlConfig: { locale: 'id-ID', currency: 'IDR' },
                })}
                                    </Badge>
                                    
                                </ListGroup.Item>
                                ))}
                            </ListGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <h1>Aset Guna</h1>
                            <ListGroup as="ol" numbered>
                                {asetGuna.map((item) => (
                                <ListGroup.Item 
                                key={item.asset_id} 
                                variant={item.active=="0" && "secondary"} 
                                action 
                                className="d-flex justify-content-between align-items-start" 
                                onClick={(key)=>{this.editData(item)}}
                                >
                                    <div className="ms-2 me-auto">
                                    <div className="fw-bold">{item.name}</div>
                                    {item.desc}
                                    </div>
                                    <Badge bg="primary" pill>
                                    {formatValue({
                value: item.amount,
                intlConfig: { locale: 'id-ID', currency: 'IDR' },
                })}
                                    </Badge>
                                    
                                </ListGroup.Item>
                                ))}
                            </ListGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <h1>Aset Investasi</h1>
                            <ListGroup as="ol" numbered>
                                {asetInvestasi.map((item) => (
                                <ListGroup.Item key={item.asset_id} variant={item.active=="0" && "secondary"} action className="d-flex justify-content-between align-items-start" onClick={(key)=>{this.editData(item)}}>
                                    <div className="ms-2 me-auto">
                                    <div className="fw-bold">{item.name}</div>
                                    {item.desc}
                                    </div>
                                    <Badge bg="primary" pill>
                                    {formatValue({
                value: item.amount,
                intlConfig: { locale: 'id-ID', currency: 'IDR' },
                })}
                                    </Badge>
                                    
                                </ListGroup.Item>
                                ))}
                            </ListGroup>
                        </Col>
                    </Row>
                        
                </Col>
                <Col md={6}>
                    <Row>
                        <Col>
                            <h1>Kewajiban Jk Pendek</h1>
                            <ListGroup as="ol" numbered>
                                {kewajibanPendek.map((item) => (
                                <ListGroup.Item key={item.asset_id} variant={item.active=="0" && "secondary"} action className="d-flex justify-content-between align-items-start" onClick={(key)=>{this.editData(item)}}>
                                    <div className="ms-2 me-auto">
                                    <div className="fw-bold">{item.name}</div>
                                    {item.desc}
                                    </div>
                                    <Badge bg="primary" pill>
                                    {formatValue({
                value: item.amount,
                intlConfig: { locale: 'id-ID', currency: 'IDR' },
                })}
                                    </Badge>
                                    
                                </ListGroup.Item>
                                ))}
                            </ListGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <h1>Kewajiban Jk Panjang</h1>
                            <ListGroup as="ol" numbered>
                                {kewajibanPanjang.map((item) => (
                                <ListGroup.Item key={item.asset_id} variant={item.active=="0" && "secondary"} action className="d-flex justify-content-between align-items-start" onClick={(key)=>{this.editData(item)}}>
                                    <div className="ms-2 me-auto">
                                    <div className="fw-bold">{item.name}</div>
                                    {item.desc}
                                    </div>
                                    <Badge bg="primary" pill>
                                    {formatValue({
                value: item.amount,
                intlConfig: { locale: 'id-ID', currency: 'IDR' },
                })}
                                    </Badge>
                                    
                                </ListGroup.Item>
                                ))}
                            </ListGroup>
                        </Col>
                    </Row>

                </Col>
            </Row>
        );
            }
            catch(e){
                //console.log(e)
            }
    }

    CreateAssetModal = (data) =>{
        return(
        <Modal show={this.state.show} onHide={this.handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Create Asset</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <CreateAssetForm 
            handleClose={this.handleClose} 
            show={this.state.show} 
            data={this.state.data} 
            edit={this.state.edit} 
            createAsset={this.props.createAsset}
            updateAsset={this.props.updateAsset}
            />
        </Modal.Body>
      </Modal>
        )
    }

    editData =(data)=>{
        //console.log(data)
        this.setState({data:data, edit:true})
        this.handleShow()
    }

    

    render(){
        return (
                <Container className="my-5">
                    <Row>
                        <Col>
                        
                        <Button as="input" type="button" value="Add Asset" onClick={this.handleShow} />
                        <this.CreateAssetModal/>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                        <this.ListAssets/>
                        
                        
                        </Col>
                    </Row>
                </Container>
        );
    }
}

const mapStateToProps = state => {
    return{
        user : state.user,
        book: state.book,
        assets: state.assets
    }
}

const mapDispatchToProps = dispatch => {
    return{
        getAssets: (book) => dispatch(getAssets(book)),
        createAsset: (book) => dispatch(createAsset(book)),
        updateAsset: (book) => dispatch(updateAsset(book)),
    }
} 
export default connect(mapStateToProps, mapDispatchToProps)(Assets);



class CreateAssetForm extends Assets{
    bookId = JSON.parse(localStorage.bookId);
    constructor(props){
        super(props);
		this.state ={
            assetId:props.data.asset_id,
            bookId:this.bookId,
            show:props.show,
			name: props.data.name,
            desc: props.data.desc,
            type: props.data.type,
            amount: props.data.amount,
            category: props.data.category,
            validated: false,
            active:props.data.active,
            edit:props.edit
		}
    }
    componentDidMount(){ 
        //this.setState({show:props.show});
        //console.log(this.state.name)
    }
    
    saveAsset = async (e) => {
        e.preventDefault();
        const form = e.currentTarget;
        //console.log(form)
        if (form.checkValidity() === false) {
          //e.preventDefault();
          e.stopPropagation();
        }
    
        this.setState({validated:true});
        if(this.state.edit){
            this.props.updateAsset(this.state).then((result)=>{
                //console.log(result)
                if(result){
                    this.props.handleClose()
                }
            }) 
        }else{
            this.props.createAsset(this.state).then((result)=>{
                //console.log(result)
                if(result){
                    this.props.handleClose()
                }
            })
        }
        
        
        //console.log(response.status)
        //navigate("/books")
    }
    catOptions=()=>{
        var option
        if(this.state.type=='1'){
            option=(
                <>
                <option></option>
                <option value="1">Aset Lancar</option>
                <option value="2">Aset Guna</option>
                <option value="3">Aset Investasi</option>
                </>
            )
        }else if(this.state.type=='2'){
            option=(
            <>
            <option></option>
                <option value="4">Jangka Pendek</option>
                <option value="5">Jangka Panjang</option>
                </>
            )
        }
        return option;
    }
    activeCheck=()=>{
        if(this.state.edit){
            return (
                <FormGroup className="mb-3">
                <Form.Check // prettier-ignore
                    type="switch"
                    id="active"
                    checked={this.state.active=="1"}
                    onChange={ (e) => this.handleActive(e)}
                    label="akun aktif"
                />
                </FormGroup>
            )
        }
    }
    handleActive=(e)=>{
        if(e.target.checked){
            this.setState({active:1})
        }else{
            this.setState({active:0})
        }
         
     }
    
    //console.log(props.handleClose)
    render(){
        return(
        <Form id="createAssetForm" validated={this.state.validated}  onSubmit={ this.saveAsset }>
                <FormGroup className="mb-3">
                    <FormLabel htmlFor={'name'}>Name</FormLabel>
                    <input type={'text'} className="form-control" id={'name'} name="name" value={ this.state.name || "" } onChange={ (e) => this.setState({name:(e.target.value)}) } required />
                </FormGroup>
                <FormGroup className="mb-3">
                    <FormLabel htmlFor={'desc'}>Description</FormLabel>
                    <Form.Control as={'textarea'} rows={3}  className="form-control" id={'desc'} name="desc" value={ this.state.desc } onChange={ (e) => this.setState({desc:(e.target.value)}) } required />
                </FormGroup>

                <FormGroup className="mb-3">
                    <Form.Select aria-label="select Type" value={this.state.type} onChange={ (e) => this.setState({type:(e.target.value)}) } required >
                        <option></option>
                        <option value="1">Aset</option>
                        <option value="2">Kewajiban</option>
                    </Form.Select>
                </FormGroup>
                
                <FormGroup className="mb-3">
                    <Form.Select aria-label="select Category" value={this.state.category} onChange={ (e) => this.setState({category:(e.target.value)}) } required >
                        <this.catOptions/>
                        
                    </Form.Select>
                </FormGroup>
                <FormGroup className="mb-3">
                    <FormLabel htmlFor={'amount'}>Total</FormLabel>
                    
                    <CurrencyInput
                        className="form-control" id={'amount'} name="amount"
                        placeholder="Please enter a number"
                        defaultValue={this.state.amount}
                        decimalsLimit={2}
                        intlConfig= {{locale: 'id-ID', currency: 'IDR'}}
                        onValueChange={(value, name, values) => this.setState({amount:value})} required
                        />
                </FormGroup>
                <this.activeCheck />
                    
                    <Button type="submit" id="update-btn">{this.state.edit?"Update Item":"Create Item"}</Button>
                    {' '}
                    
                    <Button variant="secondary" onClick={this.props.handleClose}>Cancel</Button>
                    
                
            </Form>
        )
    }
}
//connect(mapStateToProps, mapDispatchToProps)(CreateAssetForm);