import axios from "axios";
import React, { useState, useEffect, Component } from 'react'
import { Button, Col, Container, ListGroup, Row, Badge, Modal, Form, FormGroup, FormLabel, InputGroup, DropdownButton, Dropdown } from "react-bootstrap";
import {Navigate} from 'react-router-dom';
import {connect} from 'react-redux';
import {getBooksAccess, updateBook, createBook} from '../../app/actions/index';
//import "react-datepicker/dist/react-datepicker.css";

class Books extends Component{
    constructor(props){
        super(props);
        this.state ={
            book_id: '',
            name: '',
            desc: '',
            role: '',
            status: '',
            books: [],
            eligible: false,
            data:'',
            
            action: '',
            submitted:false,
            firstTime:false,
            done:false,
            navigate:false

            
        }
    }

    handleClose = () => this.setState({data:'', show:false, update:false,transactionType:''});
    handleShow = (val) => this.setState({show:true, });

    data = JSON.parse(localStorage.data);
    componentDidMount(){
        this.getBooks()
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }
    
    getBooks =()=>{
        let book = this.props.getBooksAccess(this.data.user_id).then(book=>{
            //console.log(book)
            if(Object.keys(book.data).length!==0){
                this.setState({
                    books:book.data,
                    id:book.data[0].id,
                    name:book.data[0].name,
                    desc:book.data[0].desc,
                    role:book.data[0].role,
                    status:book.data[0].status,
                    eligible:true
                })
                
                //console.log(books)
                }else{
                    this.setState({firstTime:true})
                }
        })
    }

    editData =(data)=>{
        this.setState({data:data, edit:true})
        this.handleShow()
    }
    EditBookModal = (data) =>{
        var modal=(
                <Modal show={this.state.show} onHide={this.handleClose}>
                    <Modal.Header closeButton>
                    <Modal.Title>Book Details</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <CreateBookForm 
                        handleClose={this.handleClose}    
                        show={this.state.show} 
                        data={this.state.data} 
                        edit={this.state.edit} 
                        createBook={this.props.createBook}
                        updateBook={this.props.updateBook}
                        getBooks={this.getBooks} />
                    </Modal.Body>
                </Modal>
            )
        return(
            modal
        )
    }

    ListBooks = () =>{
        //console.log(this.props.assets)
        try{
        var books = this.props.books
            
        //console.log(asetLancar)
        return (
            <Row>
                    <Col md={6}>
                    <ListGroup as="ol" numbered>
                        {this.state.books.map((item) => (
                        <ListGroup.Item key={item.id} action className="d-flex justify-content-between align-items-start" onClick={(key)=>{this.editData(item)}}>
                            <div className="ms-2 me-auto">
                            <div className="fw-bold">{item.name}</div>
                            {item.desc}
                            </div>
                            <Badge bg="primary" pill>
                            0
                            </Badge>
                            {item.id===localStorage.bookId &&
                            <Badge bg="primary" pill>
                            default
                            </Badge>
                            }
                        </ListGroup.Item>
                        ))}
                    </ListGroup>
                    </Col>
                </Row>
        );
            }
            catch(e){
                //console.log(e)
            }
    }

render(){
    
    if(this.state.navigate!== false){
        return <Navigate to={this.state.navigate} />
    }
    
    return (
        <React.Fragment>
            <Modal
                show={this.state.firstTime}
                //onHide={this.handleClose}
                backdrop="static"
                keyboard={false}
                centered
            >
                <Modal.Header closeButton={false}>
                <Modal.Title>Pengguna Pemula</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                Mari buat buku pencatatan pertama..
                </Modal.Body>
                <Modal.Footer>
                <Button variant="primary" onClick={() => this.setState({navigate:"/books/create"})}>
                    Mengerti
                </Button>
                </Modal.Footer>
            </Modal>
            <Container className="my-5">
                <h2 className="fw-normal mb-5">Book Data</h2>
                <Row>
                    <Col>
                    {!this.state.eligible && 
                    <Button as="input" type="button" value="Add Book" onClick={() => this.setState({navigate:"/books/create"})} />
                    }
                    <this.EditBookModal/>
                    </Col>
                </Row>
                <this.ListBooks />
            </Container>
        </React.Fragment>
    );
}
}


const mapStateToProps = state => {
    return{
      user : state.user,
      books: state.books
    }
}

const mapDispatchToProps = dispatch => {
    return{
        getBooksAccess: (book) => dispatch(getBooksAccess(book)),
		updateBook: (book) => dispatch(updateBook(book)),
		createBook: (book) => dispatch(createBook(book)),
    }
} 
export default connect(mapStateToProps, mapDispatchToProps)(Books);


class CreateBookForm extends Books{
    bookId = JSON.parse(localStorage.bookId);
    data = JSON.parse(localStorage.data);
    constructor(props){
        super(props);
		this.state ={
            bookId:props.data.id,
            show:props.show,
            name: props.data.name,
			desc: props.data.desc,
            validated: false,
            active:props.data.active,
            edit:props.edit,
            user_id: this.data.user_id,
            role:1,
            status:1,
		}
    }
    componentDidMount(){
    }
    
    
    
    saveBook = async (e) => {
        e.preventDefault();
        const form = e.currentTarget;
        //console.log(form)
        if (form.checkValidity() === false) {
          //e.preventDefault();
          e.stopPropagation();
        }
    
        this.setState({validated:true});
        if(this.state.edit){
            this.props.updateBook(this.state).then((result)=>{
                //console.log(result)
                if(result){
                    this.props.getBooks()
                    this.props.handleClose()
                }
            })
        }else{
            this.props.createBook(this.state).then((result)=>{
                //console.log(result)
                if(result){
                    this.props.handleClose()
                }
            })
        }
        
        
        //console.log(response.status)
        //navigate("/books")
    }
    
    //console.log(props.handleClose)
    render(){
        return(
        <Form id="createBookForm" validated={this.state.validated}  onSubmit={ this.saveBook }>
            <h1>{this.state.transactionType}</h1>
                <FormGroup className="mb-3">
                    <FormLabel htmlFor={'name'}>Name</FormLabel>
                    <input type={'text'} className="form-control" id={'name'} name="name" value={ this.state.name } onChange={ (e) => this.setState({name:e.target.value}) } required />
                </FormGroup>
                <FormGroup className="mb-3">
                    <FormLabel htmlFor={'desc'}>Description</FormLabel>
                    <Form.Control as={'textarea'} rows={3}  className="form-control" id={'desc'} name="desc" value={ this.state.desc } onChange={ (e) => this.setState({desc:e.target.value}) }  required />
                </FormGroup>
                
                <Button type="submit" id="update-btn">{this.state.edit?"Perbarui detail Buku":"Catat Buku"}</Button>
                {' '}
                
                <Button variant="secondary" onClick={this.props.handleClose}>Batal</Button>
                    
                
            </Form>
        )
    }
}