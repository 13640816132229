import React from 'react';
import ReactDOM from 'react-dom/client';
import 'bootstrap/dist/css/bootstrap.min.css';
import './index.css';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter, Route, Routes, Switch } from 'react-router-dom';
import Login from './auth/login/Login';
import Auth from './auth/Auth';
import App from './App';
import User from './portal/user/User';
import Books from './portal/books/Books'
import ReviewBook from './components/books'
import CreateBook from './portal/books/CreateBook'
import ProtectedRoute from './util/ProtectedRoute';
import Home from './portal/home/Home';
import store from './app/configureStore';
import { Provider } from 'react-redux'


import Symbol_observable from 'symbol-observable';

//const store = configureStore();
//console.info('Symbol_observable', Symbol_observable);
const root = ReactDOM.createRoot(document.getElementById('root'));
global.backend = "https://ci.prusolusi.com/";

//global.backend = "http://localhost:8080/";
//const store = configureStore();
root.render(
	<Provider store={store}>
	<React.StrictMode>
		<BrowserRouter basename={'/'}>
			<Routes>
				<Route path='/auth' element={<Auth />}>
					<Route path='login' element={<Login/>} />
				</Route>
				<Route path="/" element={<App />}>
					<Route path='' element={
						<ProtectedRoute>
							<Home />
						</ProtectedRoute>
					} />
					<Route path='user' element={
						<ProtectedRoute>
							<User />
						</ProtectedRoute>
					} />
					<Route path='books' element={
						<ProtectedRoute>
							<Books />
						</ProtectedRoute>
					} />
					<Route path='reviewbook' element={
						<ProtectedRoute>
							<ReviewBook />
						</ProtectedRoute>
					} />
					<Route path='books/create' element={
						<ProtectedRoute>
							<CreateBook />
						</ProtectedRoute>
					} />
					<Route path='books/edit/:id' element={
						<ProtectedRoute>
							<CreateBook />
						</ProtectedRoute>
					} />
				</Route>
				
			</Routes>
		</BrowserRouter>
	</React.StrictMode>
	</Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
