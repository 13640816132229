import {SET_ASSETS, ADD_ASSET, GET_ASSETS} from "../actions/actionTypes"

const initialState ={
    assets:[]
    //expiryDate: null
}
const reducer =(state = initialState, action) =>{
    switch(action.type){
        case "SET_ASSETS":
            return action.item
        case "ADD_ASSET":
            return {...state}    
        case "GET_ASSETS":
            return state 
        default:
            return state;
    }
}
export default reducer;