import {SET_TRANSACTIONS} from "../actions/actionTypes"

const initialState ={
    //assets:[]
    //expiryDate: null
}
const reducer =(state = initialState, action) =>{
    switch(action.type){
        case "SET_TRANSACTIONS":
            return action.item  
        default:
            return state;
    }
}
export default reducer;