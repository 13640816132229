import axios from "axios";
import React, { useState, useEffect, Component } from 'react'
import { Button, Col, Container, ListGroup, Row, Badge, Modal, Form, FormGroup, FormLabel } from "react-bootstrap";
import { Navigate} from 'react-router-dom';
import CurrencyInput,{ formatValue } from 'react-currency-input-field';
import {connect} from 'react-redux';
import {getCategories, setCategory} from '../app/actions/index'
//import {CreateAssetForm} from './createAsset'

class Category extends Component{
    handleClose = () => this.setState({data:'',show:false, update:false});
    handleShow = () => this.setState({show:true});
    //navigate = useNavigate()
    constructor(props){
        super(props);
		this.state ={
			show: false,
            data:'',
            update:false
		}
    }
    componentDidMount(){
        /*
        if(typeof localStorage.bookId!=="undefined"){
            var bookId = JSON.parse(localStorage.bookId);
        }
        */
        //this.props.getCategories(bookId)
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }

    ListAssets = () =>{
        //console.log(this.props.assets)
        try{
        var categories = this.props.categories
        var pemasukan = categories.filter(categori => (categori.type ==='1'))
        var pengeluaran = categories.filter(categori => (categori.type ==='2'))
        //console.log(pengeluaran)
            
        //console.log(asetLancar)
        return (
            <Row>
                <Col md={6}>
                    
                            <h1>Pemasukan</h1>
                            <ListGroup as="ol" numbered>
                                {pemasukan.map((item) => (
                                <ListGroup.Item key={item.id} action className="d-flex justify-content-between align-items-start" onClick={(key)=>{this.editData(item)}}>
                                    <div className="ms-2 me-auto">
                                    <div className="fw-bold">{item.name}</div>
                                    {item.desc}
                                    </div>
                                    <Badge bg="primary" pill>
                                    {item.budget!==null && formatValue({
                value: item.budget,
                intlConfig: { locale: 'id-ID', currency: 'IDR' },
                })}
                                    </Badge>
                                    
                                </ListGroup.Item>
                                ))}
                            </ListGroup>
                        
                    
                        
                </Col>
                <Col md={6}>
                    
                            <h1>Pengeluaran</h1>
                            <ListGroup as="ol" numbered>
                                {pengeluaran.map((item) => (
                                <ListGroup.Item key={item.id}  action className="d-flex justify-content-between align-items-start" onClick={(key)=>{this.editData(item)}}>
                                    <div className="ms-2 me-auto">
                                    <div className="fw-bold">{item.name}</div>
                                    {item.desc}
                                    </div>
                                    <Badge bg="primary" pill>
                                    {item.budget!==null && formatValue({
                value: item.budget,
                intlConfig: { locale: 'id-ID', currency: 'IDR' },
                })}
                                    </Badge>
                                    
                                </ListGroup.Item>
                                ))}
                            </ListGroup>
                      
                    

                </Col>
            </Row>
        );
            }
            catch(e){
                //console.log(e)
            }
    }

    CreateCategoryModal = (data) =>{
        return(
        <Modal show={this.state.show} onHide={this.handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Create Category</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <CreateCategoryForm handleClose={this.handleClose} show={this.state.show} data={this.state.data} update={this.state.update} action={this.props.setCategory}/>
        </Modal.Body>
      </Modal>
        )
    }

    editData =(data)=>{
        //console.log(data)
        this.setState({data:data, update:true})
        this.handleShow()
    }

    

    render(){
        return (
                <Container className="my-5">
                    <Row>
                        <Col>
                        
                        <Button as="input" type="button" value="Add Category" onClick={this.handleShow} />
                        <this.CreateCategoryModal/>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                        <this.ListAssets/>
                        
                        
                        </Col>
                    </Row>
                </Container>
        );
    }
}

const mapStateToProps = state => {
    return{
        categories: state.categories
    }
}

const mapDispatchToProps = dispatch => {
    return{
        getCategories: (book) => dispatch(getCategories(book)),
        setCategory: (book) => dispatch(setCategory(book)),
    }
} 
export default connect(mapStateToProps, mapDispatchToProps)(Category);



class CreateCategoryForm extends Category{
    bookId = JSON.parse(localStorage.bookId);
    constructor(props){
        super(props);
		this.state ={
            Id:props.data.id,
            bookId:this.bookId,
            show:props.show,
			name: props.data.name,
            desc: props.data.desc,
            type: props.data.type,
            budget: props.data.budget,
            validated: false,
            update:props.update
		}
    }
    componentDidMount(){
        //this.setState({show:props.show});
        //console.log(this.state.name)
    }
    
    saveCategory = async (e) => {
        e.preventDefault();
        const form = e.currentTarget;
        //console.log(form)
        if (form.checkValidity() === false) {
          //e.preventDefault();
          e.stopPropagation();
        }
    
        this.setState({validated:true});
    
        this.props.action(this.state).then((result)=>{
            if(result){
                this.props.handleClose()
            }
        })
        
        //console.log(response.status)
        //navigate("/books")
    }
    
    
    handleActive=(e)=>{
        if(e.target.checked){
            this.setState({active:1})
        }else{
            this.setState({active:0})
        }
         
     }
    
    //console.log(props.handleClose)
    render(){
        return(
        <Form id="createAssetForm" validated={this.state.validated}  onSubmit={ this.saveCategory }>
            <FormGroup className="mb-3">
                <Form.Select aria-label="select Type" value={this.state.type} onChange={ (e) => this.setState({type:(e.target.value)}) } required >
                    <option></option>
                    <option value="1">Pemasukan</option>
                    <option value="2">Pengeluaran</option>
                </Form.Select>
            </FormGroup>
            <FormGroup className="mb-3">
                <FormLabel htmlFor={'name'}>Name</FormLabel>
                <input type={'text'} className="form-control" id={'name'} name="name" value={ this.state.name || "" } onChange={ (e) => this.setState({name:(e.target.value)}) } required />
            </FormGroup>
            <FormGroup className="mb-3">
                <FormLabel htmlFor={'desc'}>Description</FormLabel>
                <Form.Control as={'textarea'} rows={3}  className="form-control" id={'desc'} name="desc" value={ this.state.desc } onChange={ (e) => this.setState({desc:(e.target.value)}) }  required />
            </FormGroup>

            
            
            
            <FormGroup className="mb-3">
                <FormLabel htmlFor={'budget'}>Budget</FormLabel>
                
                <CurrencyInput
                    className="form-control" id={'budget'} name="budget"
                    placeholder="Please enter a number"
                    defaultValue={this.state.budget}
                    decimalsLimit={2}
                    intlConfig= {{locale: 'id-ID', currency: 'IDR'}}
                    onValueChange={(value, name, values) => this.setState({budget:value})}
                    />
            </FormGroup>
                
                <Button type="submit" id="update-btn">{this.state.update?"Update Item":"Create Item"}</Button>
                {' '}
                
                <Button variant="secondary" onClick={this.props.handleClose}>Cancel</Button>
                
                
            </Form>
        )
    }
}
//connect(mapStateToProps, mapDispatchToProps)(CreateAssetForm);