import axios from "axios";
import React, { useState, useEffect, Component } from 'react'
import { Button, Col, Container, Form, FormGroup, FormLabel, Row } from "react-bootstrap";
import { Navigate, useSearchParams } from 'react-router-dom';
import "react-datepicker/dist/react-datepicker.css";
import {connect} from 'react-redux';
import {setBook} from '../../app/actions/index';
//import {SetDefaultBook} from "../../components/books"



class Book extends Component{
    
    data = JSON.parse(localStorage.data);
    
    constructor(props){
        super(props);
        this.state ={
            name: '',
            desc: '',
            action: '',
            submitted:false,
            firstTime:false,
            done:false,
            navigate:false,
            bookId:''
        }
        
        //id = this.props.id
    }
    componentDidUpdate(){
        if(this.props.id!== undefined){
            this.getBookById()
        }
    }
    componentDidMount(){
        //let data = JSON.parse(localStorage.data);
        
        this.getBookById()
    }
    
    
    saveBook = async (e) => {
        //e.preventDefault();
        //console.log(data.user_email);
        const response = await axios.post(global.backend+'books', {
            email: this.data.user_email,
            name: this.state.name,
            desc: this.state.desc,
            role:1,
            status:1,
            user_id:this.data.user_id, 
            
        })
        //console.log(response.data.messages['bookId'])
        localStorage.setItem("bookId",response.data.messages['bookId'])
        //console.log("go!")
        this.props.setBook(this.state)
        this.setState({navigate:"/"})
    }

    updateBook = async (e) => {
        //e.preventDefault();
        await axios.post(global.backend+'books/update/'+this.props.id, {
            name: this.state.name,
            desc: this.state.desc
        })
        this.props.setBook(this.state)
        this.setState({navigate:"/"})
    }
    
    getBookById = async () => {
        
        console.log(window.location.href.split('/')[5])
        console.log(this.props.match)
        const response = await axios.get(global.backend+'books/'+this.props.id);
        //console.log(response)
        this.setState({name:response.data[0].name})
        this.setState({desc:response.data[0].desc})
    }

    editBookById = ()=>{
        
    }



    submit = async (e)=>{
        e.preventDefault();
        //console.log(id)
        if(this.props.id!==undefined){
            this.updateBook()
        }else{
            this.saveBook()
        }
    }
render(){
    if(this.state.navigate!==false){
		return <Navigate to={this.state.navigate} />
	}
    return (
        <React.Fragment>
            <Container className="my-5">
                <h2 className="fw-normal mb-5">Create Book</h2>
                <Row>
                    <Col md={6}>
                        <Form id="loginForm" onSubmit={ this.submit }> 
                            <FormGroup className="mb-3">
                                <FormLabel htmlFor={'name'}>Name</FormLabel>
                                <input type={'text'} className="form-control" id={'name'} name="name" value={ this.state.name } onChange={ (e) => this.setState({name:e.target.value}) } required />
                            </FormGroup>
                            <FormGroup className="mb-3">
                                <FormLabel htmlFor={'desc'}>Description</FormLabel>
                                <Form.Control as={'textarea'} rows={3}  className="form-control" id={'desc'} name="desc" value={ this.state.desc } onChange={ (e) => this.setState({desc:e.target.value}) }  required />
                            </FormGroup>
                            
                            
                                
                                <Button type="submit" id="update-btn">{this.props.id? "Update" : "Create Book"}</Button>
                                {' '}
                                <Button variant="danger" onClick={() => this.setState({navigate:"/books"})}>Cancel</Button>
                                
                            
                        </Form>
                    </Col>
                </Row>
            </Container>
        </React.Fragment>
    );
}
}



const mapStateToProps = state => {
    return{
      user : state.user,
      book: state.book
    }
}

const mapDispatchToProps = dispatch => {
    return{
		setBook: (book) => dispatch(setBook(book)),
		//getUser: (user) => dispatch(getUser(user)),
    }
} 
export default connect(mapStateToProps, mapDispatchToProps)(Book);