import axios from "axios";
import { useReducer } from "react";
import store from '../configureStore';
import {SET_TRANSACTIONS,GET_ASSETS} from "../actions/actionTypes"
import { updateAsset } from "./assets";

export const getTransactions = (bookId,month,year)=>{
    /*
    var state = store.getState()
    //console.log(arr)
    var asset = state.assets.filter(asset => (asset.asset_id ==='45'))
    console.log(asset[0].amount)
    */
    return((dispatch)=>{
        //console.log("b")
        const promise = new Promise(async(resolve,reject)=>{
            try {
                const transactions = await axios.post(global.backend+'books/get_transactions/bookId='+bookId+'&month='+month+'&year='+year)
                //console.log(data.user_id)
                dispatch(setTransactions(transactions.data))
                resolve(transactions.data)
            } catch(e) {
                console.log(e)
            }
        })
        return promise
        .then(val=>{
            return val
        })

    })
}

function formatDate(date) {
    var hours = date.getHours();
    var minutes = date.getMinutes();
    var sec = date.getSeconds();
    //hours = hours % 12;
    //hours = hours ? hours : 12; // the hour '0' should be '12'
    //minutes = minutes < 10 ? '0'+minutes : minutes;
    var strTime = hours + ':' + minutes + ':' + sec;
    return date.getFullYear() +"-"+ (date.getMonth()+1) + "-" + date.getDate() + "  " + strTime;
  }

export const createTransaction = (data) => {
    //console.log(data)
    const d = new Date();
    return((dispatch)=>{
    
        var state = store.getState()
        var asset = state.assets.filter(asset => (asset.asset_id ===data.assetId))
        var category = state.categories.filter(type => (type.id ===data.categoryId))
        var updatedAmount 
        if(!category){
            if(category[0].type==1){
                updatedAmount = parseInt(asset[0].amount) + parseInt(data.amount)
            }else{
                updatedAmount = parseInt(asset[0].amount) - parseInt(data.amount)
            }
        }else{
            updatedAmount = parseInt(asset[0].amount) + parseInt(data.amount)
        }
        
        
        //console.log(asset[0].amount)
        
        const promise = new Promise(async(resolve,reject)=>{
            try{
                var response
                response = await axios.post(global.backend+'transactions', {
                    book_id: data.bookId,
                    asset_id: data.assetId,
                    desc: data.desc,
                    category_id: data.categoryId,
                    amount:data.amount,
                    date:formatDate(data.transactionDate),
                    user_id:state.user.id
                })
            
            if(response.status=="200"||response.status=="201"){
                //props.handleClose()
                dispatch(updateAsset({
                    assetId:data.assetId, 
                    bookId:data.bookId, 
                    amount:updatedAmount,
                }))
                resolve(true)
                
            }
            //console.log(response.status)
            }catch(e){
                console.log(e)
            }
    //navigate("/books")
        })
        return promise
        .then(val=>{
            dispatch(getTransactions(data.bookId,d.getMonth()+1,d.getFullYear()))
            return val
        })
    })
}

export const updateTransaction = (data) => {
    const d = new Date();
    return((dispatch)=>{
        var state = store.getState()
        const promise = new Promise(async(resolve,reject)=>{
            try{
                var response = await axios.post(global.backend+'transactions/update/'+data.Id, {
                    book_id: data.bookId,
                    asset_id: data.assetId,
                    desc: data.desc,
                    category_id: data.categoryId,
                    amount:data.amount,
                    date:formatDate(data.transactionDate),
                    user_id:state.user.id
                })
            
            if(response.status=="200"||response.status=="201"){
                //props.handleClose()
                resolve(true)
            }else{
                resolve(false)
            }
            //console.log(response.status)
            }catch(e){
                console.log("here:"+e)
            }
    //navigate("/books")
        })
        return promise
        .then(val=>{
            dispatch(getTransactions(data.bookId,d.getMonth()+1,d.getFullYear()))
            return val
        })
    })
}

export const setTransactions = (item) => {
    //AsyncStorage.setItem("grow:auth:user",user.uid);
    //console.log(user)
    //addUser(user)
    //console.log(item)
    return{
        type: SET_TRANSACTIONS,
        item: item,
    }
}
