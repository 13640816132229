import axios from "axios";
import React, { Component, useState, useEffect } from 'react'
import { Button, Col, Container, Form, FormGroup, FormLabel, Row, Modal } from "react-bootstrap";
import { Navigate} from 'react-router-dom';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {connect} from 'react-redux';
import {setUser,updateUser, createUser} from '../../app/actions/index';

class User extends Component{
    data = JSON.parse(localStorage.data);
    
    
    constructor(props){
        super(props);
        this.state ={
            user_id: this.data.user_id,
            email:this.data.user_email,
            name: '',
            phone: '',
            dob: '',
            profession: '',
            sex: 0,
            location: '',
            marriage: 0,
            smoking: 0,
            bio: '',
            province: [],
            action: '',
            //provinceList:[],
            submitted:false,
            firstTime:false,
            done:false
        }
    }
    
    //const navigate = useNavigate()
    
    handleClose = () =>{
        this.setState({firstTime:false});
        this.setState({submitted:false});
    } 
    handleShow = () => {
        this.setState({firstTime:true});
        this.setState({submitted:true});
    }
    handleDone = () =>{
        this.setState({done:true})
    }


    handleChangeDate = (date, event) => {
        //const d = new Date(date).toLocaleDateString('id-ID');
      //console.log(d);
      //setDate(d);
        //console.log(date);
        this.setState({dob:date})
        //console.log(date);
      };
      //console.log(data.user_email)
      
    submit = async (e) => {
        e.preventDefault();
        if(this.state.action=="create"){
            this.props.createUser(this.state)
            .then((response)=>{
                if(response){
                    this.setState({submitted:true})
                }else{
                    alert("something wrong, contact admin!")
                }
            }
            )
        }else{
            this.props.updateUser(this.state)
            .then((response)=>{
                if(response){
                    this.setState({submitted:true})
                }else{
                    alert("something wrong, contact admin!")
                }
            }
            )
        }
        
        
    }
    /*
    useEffect(() => {
        getUser()
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    */
    componentDidMount(){
		
		this.getUser()
		//.then((route)=>{
		//	this.setState({redirect:true, needUpdate:route})
		//})
	}
    getUser = async() => {
//console.log(this.data)
        const user = await axios.get(global.backend+'users/'+this.data.user_id)
        const provinceList = await axios.get('https://www.emsifa.com/api-wilayah-indonesia/api/provinces.json')
        this.setState({province:provinceList.data})
        //console.log(user.data[0])
        //this.setProvince(provinceList.data)
        if(Object.keys(user.data).length!==0){
            this.setState({
                user_id:user.data[0].ID,
                name:user.data[0].name,
                phone:user.data[0].phone,
                dob:new Date(user.data[0].dob),
                profession:user.data[0].profession,
                sex:user.data[0].sex,
                location:user.data[0].location,
                marriage:user.data[0].marriage,
                smoking:user.data[0].smoking,
                bio:user.data[0].bio,
                action:'update'
            })
        }else{
            this.setState({
                action:'create',
                user_id:this.data.user_id,
                firstTime:true
            })
        }
        
        //console.log(provinceList.data)
    }
    locationSelector = (id) =>{
        
        return(
            <FormGroup className="mb-3">
                <FormLabel htmlFor={'location'}>Location</FormLabel>
                <Form.Select name="location" value={this.state.location} aria-label="Default select location" onChange={ (e) => this.setState({location:e.target.value}) } required>
                    <option key="" value="" /*selected={(data.id===this.state.location)}*/>--pilih provinsi--</option>
                {this.state.province.map((data)=>
                    <option key={data.id} value={data.id} /*selected={(data.id===this.state.location)}*/>{data.name}</option>
                )}
                </Form.Select>
            </FormGroup>
        )
    }

    handleSmoking=(e)=>{
        //console.log(e.target.checked)
        //var checke
       
        this.setState({smoking:e.target.checked})
         
     }

      


render(){
    if(this.state.done){
		return <Navigate to={"/"} />
	}
    return (
        <React.Fragment>
            <Modal
                show={this.state.firstTime}
                onHide={this.handleClose}
                backdrop="static"
                keyboard={false}
                centered
            >
                <Modal.Header closeButton>
                <Modal.Title>Pengguna Pemula</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                Mari Lengkapi Data Dahulu..
                </Modal.Body>
                <Modal.Footer>
                <Button variant="primary" onClick={this.handleClose}>
                    Mengerti
                </Button>
                </Modal.Footer>
            </Modal>
            <Modal
                show={this.state.submitted}
                onHide={this.handleClose}
                backdrop="static"
                keyboard={false}
                centered
            >
                <Modal.Header closeButton>
                <Modal.Title>Status</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                Data berhasil diubah
                </Modal.Body>
                <Modal.Footer>
                <Button variant="primary" onClick={this.handleDone}>
                    kembali ke Home
                </Button>
                <Button variant="secondary" onClick={this.handleClose}>
                    Tetap disini
                </Button>
                </Modal.Footer>
            </Modal>
            <Container className="my-5">
                <h2 className="fw-normal mb-5">User Data</h2>
                <Row>
                    <Col md={6}>
                        <Form id="loginForm" onSubmit={ this.submit }>
                            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                <Form.Label>Email address</Form.Label>
                                <Form.Control plaintext value={ this.data.user_email } readOnly />
                            </Form.Group>
                            <FormGroup className="mb-3">
                                <FormLabel htmlFor={'name'}>Name</FormLabel>
                                <input type={'text'} className="form-control" id={'name'} name="name" value={ this.state.name } onChange={ (e) => this.setState({name:e.target.value}) } required />
                            </FormGroup>
                            <FormGroup className="mb-3">
                                <FormLabel htmlFor={'phone'}>Phone</FormLabel>
                                <input type={'text'} className="form-control" id={'phone'} name="phone" value={ this.state.phone } onChange={ (e) => this.setState({phone:e.target.value}) }  required />
                            </FormGroup>
                            <FormGroup className="mb-3">
                                <FormLabel htmlFor={'dob'}>Date of Birth</FormLabel>
                                <Row>
                                <Col>
                                <DatePicker className="form-control"
                                selected={this.state.dob}
                                onChange={(date)=>this.handleChangeDate(date)}
                                dateFormat="dd/MMM/yyyy"
                                maxDate={new Date()}
                                calendarClassName="form-control"
                                peekNextMonth
                                showMonthDropdown
                                showYearDropdown
                                dropdownMode="select"
                                />
                                </Col></Row>
                            </FormGroup>
                            <FormGroup className="mb-3">
                                <FormLabel htmlFor={'profession'}>Profession</FormLabel>
                                <input type={'text'} className="form-control" id={'profession'} name="profession" value={ this.state.profession } onChange={ (e) => this.setState({profession:e.target.value}) }  required />
                            </FormGroup>
                            <FormGroup className="mb-3">
                                <FormLabel htmlFor={'sex'}>Sex</FormLabel>
                                <div key={`inline-sex`} className="mb-3">
                                <Form.Check
                                    inline
                                    label="female"
                                    name="sex"
                                    type="radio"
                                    value="0"
                                    checked={this.state.sex==0}
                                    id={`inline-sex-0`}
                                    onChange={ (e) => this.setState({sex:e.target.value})}
                                />
                                <Form.Check
                                    inline
                                    label="male"
                                    name="sex"
                                    type="radio"
                                    value="1"
                                    checked={this.state.sex==1}
                                    id={`inline-sex-1`}
                                    onChange={ (e) => this.setState({sex:e.target.value})}
                                />
                                </div>
                            </FormGroup>
                            {this.locationSelector(this.state.location)}
                            <FormGroup className="mb-3">
                                <FormLabel htmlFor={'marriage'}>Status</FormLabel>
                                <div key={`inline-marriage`} className="mb-3">
                                <Form.Check
                                    inline
                                    label="single"
                                    name="status"
                                    type="radio"
                                    value="0"
                                    checked={this.state.marriage==0}
                                    id={`inline-marriage-0`}
                                    onChange={ (e) => this.setState({marriage:e.target.value})}
                                />
                                <Form.Check
                                    inline
                                    label="married"
                                    name="status"
                                    type="radio"
                                    value="1"
                                    checked={this.state.marriage==1}
                                    id={`inline-marriage-1`}
                                    onChange={ (e) => this.setState({marriage:e.target.value})}
                                />
                                <Form.Check
                                    inline
                                    label="widow"
                                    name="status"
                                    type="radio"
                                    value="2"
                                    checked={this.state.marriage==2}
                                    id={`inline-marriage-2`}
                                    onChange={ (e) => this.setState({marriage:e.target.value})}
                                />
                                </div>
                            </FormGroup>
                            <FormGroup className="mb-3">
                                <FormLabel htmlFor={'smoking'}>Smoking</FormLabel>
                                <div key={`inline-smoking`} className="mb-3">
                                <Form.Check
                                    id="smoking"
                                    label="yes"
                                    name="smoking"
                                    type="switch"
                                    checked={this.state.smoking==1}
                                    onChange={ (e) => this.handleSmoking(e)}
                                />
                                
                                </div>
                            </FormGroup>
                            <FormGroup className="mb-3">
                                <FormLabel htmlFor={'bio'}>Bio</FormLabel>
                                <Form.Control as={'textarea'} rows={3} className="form-control" id={'bio'} name="bio" value={ this.state.bio } onChange={ (e) => this.setState({bio:e.target.value}) } required />
                            </FormGroup>
                            
                                
                                <Button type="submit" id="update-btn">Update</Button>
                                {' '}
                                <Button variant="danger" onClick={()=>this.setState({done:true})}>Cancel</Button>
                                
                            
                        </Form>
                    </Col>
                </Row>
            </Container>
        </React.Fragment>
    );
}
}

const mapStateToProps = state => {
    return{
      user : state.user,
      book: state.book
    }
}

const mapDispatchToProps = dispatch => {
    return{
		//getUserWP: (user) => dispatch(getUserWP(user)),
		setUser: (user) => dispatch(setUser(user)),
        createUser: (user) => dispatch(createUser(user)),
        updateUser: (user) => dispatch(updateUser(user)),
    }
} 
export default connect(mapStateToProps, mapDispatchToProps)(User);