

export const SET_USER = "SET_USER"
export const SET_BOOK = "SET_BOOK"
export const SET_TOKEN = "SET_TOKEN"
export const GET_USER = "GET_USER"
export const SET_ASSETS = "SET_ASSETS"
export const ADD_ASSET = "ADD_ASSET"
export const GET_ASSETS = "GET_ASSETS"
export const SET_CATEGORIES = "SET_CATEGORIES"
export const ADD_CATEGORY = "ADD_CATEGORY"
export const SET_TRANSACTIONS = "SET_TRANSACTIONS"
