import axios from "axios";
import React, {Component} from "react";
import { Button, Col, Container, Form, FormGroup, FormLabel, Row } from "react-bootstrap";
import { Redirect, useNavigate } from "react-router-dom";
import {connect} from 'react-redux';
import {getUserWP} from '../../app/actions/index';

const Login=()=>{
    const loginAPI = 'https://prusolusi.com/wp-json/jwt-auth/v1/token';
    
    const navigate = useNavigate();
    const submitLoginForm = (event) => {
        //redirect("/");
        event.preventDefault();
        //const navigate = useNavigate();
        let formElement = document.querySelector('#loginForm');
        const formData = new FormData(formElement);
        const formDataJSON = Object.fromEntries(formData);
        let btnPointer = document.querySelector('#login-btn');
       
        
        btnPointer.innerHTML = 'Please wait..';
        btnPointer.setAttribute('disabled', true);

        
        
        let myPromise = new Promise(function(myResolve, myReject) {
            axios.post(loginAPI, formDataJSON).then((response) => {
                btnPointer.innerHTML = 'Login';
                btnPointer.removeAttribute('disabled');
                const data = response.data;
                //console.log(data);
                const token = data.token;
                if (!token) {
                    alert('Unable to login. Please try after some time.');
                    return;
                }
                localStorage.clear();
                localStorage.setItem('user-token', token);
                localStorage.setItem('data', JSON.stringify(data));
                //console.log(data.user_id)
                //getUser(data).then((val)=>{
                    //console.log(val)
                    //myResolve(val)
                    myResolve("/")
                //})
                .catch(()=>myReject("getuser error"))
                    
                
                    
                
            }).catch((error) => {
                myReject("Error")
            })
        })
        myPromise.then(
            function(value) {navigate(value)},
            function(error) {
                console.log(error)
                btnPointer.innerHTML = 'Login';
                btnPointer.removeAttribute('disabled');
                alert("Oops! Some error occured.");}
          );
          
    }


        return(
            <React.Fragment>
                <Container className="my-5">
                    <h2 className="fw-normal mb-5">Login To Cashflow by GROW team</h2>
                    <Row>
                        <Col md={{span: 6}}>
                            <Form id="loginForm" onSubmit={submitLoginForm}>
                                <FormGroup className="mb-3">
                                    <FormLabel htmlFor={'login-username'}>Username</FormLabel>
                                    <input type={'text'} className="form-control" id={'login-username'} name="username" required />
                                </FormGroup>
                                <FormGroup className="mb-3">
                                    <FormLabel htmlFor={'login-password'}>Password</FormLabel>
                                    <input type={'password'} className="form-control" id={'login-password'} name="password" required />
                                </FormGroup>
                                <Button type="submit" className="btn-success mt-2" id="login-btn">Login</Button>
                            </Form>
                        </Col>
                    </Row>
                </Container>
            </React.Fragment>
        );
        
}



export default Login;