import {SET_USER, SET_TOKEN} from "../actions/actionTypes"

const initialState ={
    
}
const reducer =(state = initialState, action) =>{
    switch(action.type){
        case SET_USER:
            return action.item
            
        case SET_TOKEN:
            return{
                ...state,
                token:action.item
            }
        default:
            return state;
    }
}
export default reducer;