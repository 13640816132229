import axios from "axios";
import {SET_CATEGORIES, ADD_CATEGORY} from "../actions/actionTypes"

export const getCategories = (bookId)=>{
    return((dispatch)=>{
        //console.log("b")
        const promise = new Promise(async(resolve,reject)=>{
            try {
                const categories = await await axios.post(global.backend+'books/get_categories/'+bookId)
                //console.log(categories)
                dispatch(setCategories(categories.data))
                resolve(categories.data)
            } catch(e) {
                console.log(e)
            }
        })
        return promise
        .then(val=>{
            return val
        })

    })
}

export const setCategory = (data) => {
    //console.log(data)
    return((dispatch)=>{
        const promise = new Promise(async(resolve,reject)=>{
            try{
                var response
                if(data.update){
                    response = await axios.post(global.backend+'categories/update/'+data.Id, {
                        book_id: data.bookId,
                        name: data.name,
                        desc: data.desc,
                        type:data.type,
                        budget:data.budget,
                    })
                }else{
                    response = await axios.post(global.backend+'categories', {
                        book_id: data.bookId,
                        name: data.name,
                        desc: data.desc,
                        type:data.type,
                        budget:data.budget,
                    })
                }
            
            if(response.status=="200"||response.status=="201"){
                //props.handleClose()
                resolve(true)
            }
            //console.log(response.status)
            }catch(e){
                console.log(e)
            }
    //navigate("/books")
        })
        return promise
        .then(val=>{
            dispatch(getCategories(data.bookId))
            return val
        })
    })
}

export const setCategories = (item) => {
    //AsyncStorage.setItem("grow:auth:user",user.uid);
    //console.log(user)
    //addUser(user)
    //console.log(item)
    return{
        type: SET_CATEGORIES,
        item: item,
    }
}
export const addCategory = (item) => {
    //AsyncStorage.setItem("grow:auth:user",user.uid);
    //console.log(user)
    //addUser(user)
    //console.log(item)
    return{
        type: ADD_CATEGORY,
        item: item,
    }
}