import React, { useState, useEffect, Component } from 'react'
import axios from 'axios'
import { Container, Tab, Tabs, Modal, Button} from 'react-bootstrap';
import { Navigate } from "react-router-dom";
import Assets from '../../components/assets'
import Categories from '../../components/category'
import Transactions from '../../components/transactions'
import {connect} from 'react-redux';
import {getUser, getBook, getTransactions, getAssets, getCategories} from '../../app/actions/index';
import { toHaveAccessibleDescription } from '@testing-library/jest-dom/dist/matchers';

class Home extends Component {
	
	//const navigate = useNavigate();
	//const [bookName, setBookName] = useState('')
	
	//redirect = false;
	//needUpdate = false;
	constructor(props){
        super(props);
		this.state ={
			bookName: '',
			key:"Transaction",
			firstTime:false,
			fTDesc:false,
			user_id:"",
			name:"",
			phone:"",
			dob:"",
			profession:"",
			sex:"",
			location:"",
			marriage:"",
			smoking:"",
			bio:"",
			month:""
		}
    }

	componentDidMount(){
		
		let data = JSON.parse(localStorage.data);
		//console.log("1")
		let user = this.props.getUser(data.user_id).then(user=>{
			
			if(user=="new"){
				this.setState({redirect:true, needUpdate:"/user"})
			}else{
				
				this.setState({
					user_id:user.data[0].ID,
					name:user.data[0].name,
					phone:user.data[0].phone,
					dob:new Date(user.data[0].dob),
					profession:user.data[0].profession,
					sex:user.data[0].sex,
					location:user.data[0].location,
					marriage:user.data[0].marriage,
					smoking:user.data[0].smoking,
					bio:user.data[0].bio,
					//action:'update'
				})
				//console.log(user.data[0])
				for (var user_data in user.data[0]) {
					//console.log(user_data+":"+user.data[0][user_data]);
					if(((!user.data[0][user_data] || user.data[0][user_data] == "") && user_data!="default_book_id") && user_data!=='user_activation_key'){
						//console.log(user.data[0][user_data])
						this.setState({redirect:true, needUpdate:"/user"})
					}
					if(user.data[0]["default_book_id"]==""||user.data[0]["default_book_id"]== null){
						this.setState({redirect:true, needUpdate:"/books"})
					}else{
						//console.log("here")
						//this.props.getBook(user.data[0]["default_book_id"])
						//resolve('/')
					}
				}
				//console.log(user.data[0]["default_book_id"])
				if(user.data[0]["default_book_id"]!==null){
					this.props.getBook(user.data[0]["default_book_id"]).then(book=>{
						const active_month = new Date(book.data[0].active_month)
						const today = new Date()
						//console.log(today.getUTCMonth() + 1)
						if((today.getUTCMonth()!==active_month.getUTCMonth())||today.getUTCFullYear()!==active_month.getUTCFullYear()){
							//konfirmasi tutup buku
							if(window.confirm("bulan aktif buku adalah "+(active_month.getUTCMonth()+1)+", ganti dengan bulan sekarang?")==true){
								//page tutup buku
								this.setState({redirect:true, needUpdate:"/reviewbook"})
							}
						}
					})
					localStorage.setItem("bookId",user.data[0]["default_book_id"])
				}
			}
			
			this.checkAllEssentials()
		})
		
		
	}
	/*
    this.getUser = async() => {
		
        const user = await axios.get(global.backend+'users/'+data.user_id)
		//const access = await axios.get('http://localhost:8080/access/'+data.user_email)
        //setProducts(products.data)
		console.log(user);
		//var empty = false;
		//var bookId="";
		if(Object.keys(user.data).length===0){
			navigate('/user');
		}
		for (var user_data in user.data[0]) {
			//console.log(user_data+":"+user.data[0][user_data]);
			if(((!user.data[0][user_data] || user.data[0][user_data] == "") && user_data!="default_book_id") && user_data!=='user_activation_key'){
				//empty = true;
				//console.log(user_data+' is empty');
				navigate('/user');
			}
			if(user.data[0]["default_book_id"]==""||user.data[0]["default_book_id"]== null){
				navigate('/books')
			}
			localStorage.setItem("bookId",user.data[0]["default_book_id"])
		}
		
		const book = await axios.get(global.backend+'books/'+localStorage.bookId)
		this.setState({bookName: book.data[0].name})
		*/
		handleClose = () =>{
			this.setState({firstTime:false});
			this.setState({submitted:false});
		} 
		tabSelect=(key)=>{
			this.checkAllEssentials()
			this.setState({key:key})
		}	
		checkAllEssentials=()=>{
			const d = new Date();
			if(typeof localStorage.bookId!=="undefined"){
				var bookId = JSON.parse(localStorage.bookId);
				this.props.getAssets(bookId)
				.then(val=>{
					//console.log(val)
					if(val.length==0){
						//goto asset page
						this.setState({key:"Balance", firstTime:true, fTDesc:"minimal isi 1 ASSET LANCAR"})
					}else{
						this.props.getCategories(bookId)
						.then(val=>{
							//console.log(val)
							if(val.length==0){
								//goto asset page
								this.setState({key:"Category", firstTime:true, fTDesc:"minimal isi 1 CATEGORY pemasukan dan pengeluaran"})
							}
							else{
								//this.setState({key:false})
							}
						})
					}
					this.props.getTransactions(bookId,d.getMonth()+1,d.getFullYear())
				})
				
				
			}
		}
    

render(){
	if(this.state.redirect){
		return <Navigate to={this.state.needUpdate} />
	}
	return (
		
		<React.Fragment>
			<Modal
                show={this.state.firstTime}
                onHide={this.handleClose}
                backdrop="static"
                keyboard={false}
                centered
            >
                <Modal.Header closeButton>
                <Modal.Title>Pengguna Pemula</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                {this.state.fTDesc}
                </Modal.Body>
                <Modal.Footer>
                <Button variant="primary" onClick={this.handleClose}>
                    Mengerti
                </Button>
                </Modal.Footer>
            </Modal>
			<Container className='py-5'>
				<h3 className='fw-normal'>Welcome, {this.props.user.name}</h3>
				<h4>Book: {this.props.books.name}</h4>
				<h5>{this.props.books.desc}</h5>
				<Tabs
				id="main-tab"
				className="mb-3"
				activeKey={this.state.key}
				onSelect={this.tabSelect}
				>
					<Tab eventKey="Transaction" title="Transaksi">
						<Transactions/>
					</Tab>
					<Tab eventKey="Balance" title="Neraca">
						<Assets/>
					</Tab>
					<Tab eventKey="Category" title="Anggaran">
						<Categories/>	
					</Tab>
				</Tabs>
			</Container>
		</React.Fragment>
	)
}
}

const mapStateToProps = state => {
    return{
      user : state.user,
      books: state.books,
	  assets: state.assets,
	  categories: state.categories
    }
}

const mapDispatchToProps = dispatch => {
    return{
		getUser: (user) => dispatch(getUser(user)),
		getBook: (book) => dispatch(getBook(book)),
		getTransactions: (book,month,year) => dispatch(getTransactions(book,month,year)),
        getAssets: (book) => dispatch(getAssets(book)),
        getCategories: (book) => dispatch(getCategories(book)),
    }
} 
export default connect(mapStateToProps, mapDispatchToProps)(Home);