
import {createStore, combineReducers, compose, applyMiddleware} from 'redux';
import { devToolsEnhancer } from 'redux-devtools-extension';
import { configureStore} from '@reduxjs/toolkit'
import thunk from "redux-thunk";
import assetsReducer from './reducers/assets';
import userReducer from './reducers/user';
import booksReducer from './reducers/books';
import categoriesReducer from './reducers/categories';
import transactionsReducer from './reducers/transactions';


//import bookReducer from './reducers/book'
//import miscReducer from './reducers/misc'
//import miscTransaction from './reducers/transaction'
//import userReducer from './reducers/user'




const storereducer = {
    assets:assetsReducer,
    user: userReducer,
    books: booksReducer,
    categories: categoriesReducer,
    transactions: transactionsReducer
};

const composeEnhancers = devToolsEnhancer({});

const preloadedState = {};

const store = configureStore({
    reducer: storereducer,
  });
  export default store;