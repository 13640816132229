import axios from "axios";
import React, { useState, useEffect, Component } from 'react'
import { Button, Col, Container, ListGroup, Row, Badge, Modal, Form, FormGroup, FormLabel, InputGroup, DropdownButton, Dropdown } from "react-bootstrap";
import { Navigate} from 'react-router-dom';
import CurrencyInput,{ formatValue } from 'react-currency-input-field';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {connect} from 'react-redux';
import {getTransactions, createTransaction, updateTransaction, getAssets, getCategories} from '../app/actions/index'
//import {CreateAssetForm} from './createAsset'

class Transactions extends Component{
    handleClose = () => this.setState({data:'', show:false, update:false,transactionType:'', edit:false});
    handleShow = (val) => this.setState({transactionType:val, show:true, });
    //navigate = useNavigate()
    constructor(props){
        super(props);
		this.state ={
			show: false,
            data:'',
            edit:false,
            transactionType:'',
            startDate: new Date(),
            redirect: false,
            needUpdate: false
		}
    }
    componentDidMount(){
        
        
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }

    

    CreateTransactionModal = (data) =>{
        //console.log(this.props.assets)
        if(this.props.books.month=="null"){
            
        }
        var modal
        if(this.state.transactionType=="transfer"){
            modal=(
                <Modal show={this.state.show} onHide={this.handleClose}>
                    <Modal.Header closeButton>
                    <Modal.Title>transfer</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <TransferForm 
                        handleClose={this.handleClose} 
                        transactionType={this.state.transactionType} 
                        assets={this.props.assets} 
                        categories={this.props.categories} 
                        show={this.state.show} 
                        data={this.state.data} 
                        edit={this.state.edit} 
                        createTransaction={this.props.createTransaction}/>
                    </Modal.Body>
                </Modal>
            )
        }else{
            modal=(
                <Modal show={this.state.show} onHide={this.handleClose}>
                    <Modal.Header closeButton>
                    <Modal.Title>catat transaksi</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <CreateTransactionForm 
                        handleClose={this.handleClose} 
                        transactionType={this.state.transactionType} 
                        assets={this.props.assets} 
                        categories={this.props.categories} 
                        show={this.state.show} 
                        data={this.state.data} 
                        edit={this.state.edit} 
                        createTransaction={this.props.createTransaction}
                        updateTransaction={this.props.updateTransaction}/>
                    </Modal.Body>
                </Modal>
            )
        }
        return(
            modal
        )
    }

    editData =(data)=>{
        //console.log(data)
        this.setState({data:data, edit:true})
        /*
        if(data.type=="1"){
            var data = data.push(transactionType=>"pendapatan")
            this.setState({data:data})
        }
        */
        this.handleShow()
    }
    changeTransactionMonth=(date)=>{
        this.setState({startDate:date})
        var bookId = JSON.parse(localStorage.bookId);
        //console.log(date.getMonth())
        this.props.getTransactions(bookId,date.getMonth()+1,date.getFullYear())
    }

    monthPicker =()=>{
        const minOffset = 0;
        const maxOffset = 60; 
        return(
            
            <DatePicker
                selected={this.state.startDate}
                onChange={(date) => this.changeTransactionMonth(date)}
                dateFormat="MMM-yyyy"
                showMonthYearPicker
            />
        )
    }

    ListTransaksi = () =>{
        //console.log(this.props.assets)
        try{
        var transactions = this.props.transactions
            
        //console.log(asetLancar)
        return (
            <Row>
                        <Col>
                            <ListGroup as="ol" numbered>
                                {transactions.map((item) => (
                                <ListGroup.Item 
                                key={item.transaction_id} 
                                variant={item.type=="2" && "danger"} 
                                action 
                                className="d-flex justify-content-between align-items-start" 
                                onClick={(key)=>{this.editData(item)}}>
                                    <div className="ms-2 me-auto">
                                    <div className="fw-bold">{item.asset_name}</div>
                                    <div className="fw-bold">{item.category_name}</div>
                                    <div className="fw-bold">{item.user_name}</div>
                                    <div className="fw-bold">{(item.date)}</div>
                                    {item.desc}
                                    </div>
                                    <Badge bg="primary" pill>
                                    {formatValue({
                value: item.amount,
                intlConfig: { locale: 'id-ID', currency: 'IDR' },
                })}
                                    </Badge>
                                    
                                </ListGroup.Item>
                                ))}
                            </ListGroup>
                        </Col>                
            </Row>
        );
            }
            catch(e){
                //console.log(e)
            }
    }

    

    render(){
        if(this.state.redirect){
            return <Navigate to={this.state.needUpdate} />
        }
        return (
                <Container className="my-5">
                    <Row>
                        <Col>
                        
                        <Button as="input" type="button" value="catat transaksi pendapatan" onClick={()=>this.handleShow("pendapatan")} /> <></>
                        <Button as="input" type="button" variant="danger" value="catat transaksi pengeluaran" onClick={()=>this.handleShow("pengeluaran")} /> <></>
                        <Button as="input" type="button" variant="danger" value="transaksi antar rekening" onClick={()=>this.handleShow("transfer")} />
                        <this.CreateTransactionModal/>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <this.monthPicker />
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                        
                        <this.ListTransaksi />
                        
                        </Col>
                    </Row>
                </Container>
        );
    }
}

const mapStateToProps = state => {
    return{
        user : state.user,
        books: state.books,
        assets: state.assets,
        categories: state.categories,
        transactions: state.transactions
    }
}

const mapDispatchToProps = dispatch => {
    return{
        getTransactions: (book,month,year) => dispatch(getTransactions(book,month,year)),
        createTransaction: (book) => dispatch(createTransaction(book)),
        updateTransaction: (book) => dispatch(updateTransaction(book)),
        getAssets: (book) => dispatch(getAssets(book)),
        getCategories: (book) => dispatch(getCategories(book)),
    }
} 
export default connect(mapStateToProps, mapDispatchToProps)(Transactions);



class CreateTransactionForm extends Transactions{
    bookId = JSON.parse(localStorage.bookId);
    data = JSON.parse(localStorage.data);
    constructor(props){
        super(props);
		this.state ={
            Id:props.data.transaction_id,
            assetId:props.data.asset_id,
            bookId:this.bookId,
            show:props.show,
			desc: props.data.desc,
            transactionType: props.data.transactionType,
            amount: props.data.amount,
            categoryId: props.data.category_id,
            validated: false,
            active:props.data.active,
            edit:props.edit,
            assets:props.assets,
            categories:props.categories,
            transactionDate:new Date(),
            user_id: this.data.user_id
		}
    }
    componentDidMount(){
        //this.setState({show:props.show});
        //console.log(this.state.name)
        //var bookId = JSON.parse(localStorage.bookId);
        
        //this.props.getAssets(bookId)
        if(typeof(this.props.transactionType)!=="undefined"){
            this.setState({transactionType:this.props.transactionType})
        }
    }
    
    
    getAssets = () => {
        var assets = this.state.assets
        
        //if(this.state.transactionType=="income"){
            assets = assets.filter(asset => (asset.type ==='1' && asset.active ==='1' && asset.category ==='1'))
            //this.setState({assetId:(assets[0].asset_id)})
        //}else{
       //     assets = assets.filter(asset => (asset.type ==='2'))
        //}
        //console.log(this.state.transactionType)
        return(
            <FormGroup className="mb-3">
                <FormLabel htmlFor={'akun'}>Akun</FormLabel>
                    <Form.Select aria-label="select Type" id="akun" value={this.state.assetId} onChange={ (e) => this.setState({assetId:(e.target.value)}) } required >
                        <option></option>
                    {assets.map((item,index) => (
                        <option key={index} value={item.asset_id}>{item.name}</option>
                    ))}
                    </Form.Select>
                </FormGroup>
        )
    }
    getCategories = () => {
        var categories = this.state.categories
        //console.log(categories)
        if(this.state.transactionType=="pendapatan"){
            categories = categories.filter(category => (category.type ==='1'))
        }else{
            categories = categories.filter(category => (category.type ==='2'))
        }
        //console.log(this.state.transactionType)
        return(
            <FormGroup className="mb-3">
                <FormLabel htmlFor={'category'}>kategori</FormLabel>
                    <Form.Select aria-label="select Type" id="category" value={this.state.categoryId} onChange={ (e) => this.setState({categoryId:(e.target.value)}) } required >
                    <option></option>
                    {categories.map((item,index) => (
                        <option key={index} value={item.id}>{item.name}</option>
                    ))}
                    </Form.Select>
                </FormGroup>
        )
    }
    
    handleChangeDate = (date, event) => {
        //const d = new Date(date).toLocaleDateString('id-ID');
      //console.log(d);
      //setDate(d);
        //console.log(date);
        this.setState({transactionDate:date})
        //console.log(date);
      };
      //console.log(data.user_email)
      formatDate(date) {
        var d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();
    
        if (month.length < 2) 
            month = '0' + month;
        if (day.length < 2) 
            day = '0' + day;
    
        return [year, month, day].join('-');
    }

    saveTransaction = async (e) => {
        e.preventDefault();
        const form = e.currentTarget;
        //console.log(form)
        if (form.checkValidity() === false) {
          //e.preventDefault();
          e.stopPropagation();
        }
    
        this.setState({validated:true});
        if(this.state.edit){
            this.props.updateTransaction(this.state).then((result)=>{
                //console.log(result)
                if(result){
                    this.props.handleClose()
                }
            })
        }else{
            this.props.createTransaction(this.state).then((result)=>{
                //console.log(result)
                if(result){
                    this.props.handleClose()
                }
            })
        }
        
        
        //console.log(response.status)
        //navigate("/books")
    }
    
    //console.log(props.handleClose)
    render(){
        return(
        <Form id="createTransactionForm" validated={this.state.validated}  onSubmit={ this.saveTransaction }>
            <h1>{this.state.transactionType}</h1>
            <this.getAssets />

            <this.getCategories />

            <FormGroup className="mb-3">
                <FormLabel htmlFor={'date'}>Tanggal Transaksi</FormLabel>
                <Row>
                <Col>
                <DatePicker className="form-control"
                selected={this.state.transactionDate}
                onChange={(date)=>this.handleChangeDate(date)}
                dateFormat="dd/MMM/yyyy"
                maxDate={new Date()}
                calendarClassName="form-control"
                peekNextMonth
                showMonthDropdown
                showYearDropdown
                dropdownMode="select"
                />
                </Col></Row>
            </FormGroup>

            <FormGroup className="mb-3">
                <FormLabel htmlFor={'desc'}>Description</FormLabel>
                <Form.Control 
                as={'textarea'} 
                rows={3}  
                className="form-control" 
                id={'desc'} 
                name="desc" 
                value={ this.state.desc } 
                onChange={ (e) => this.setState({desc:(e.target.value)}) } required  />
            </FormGroup>

            
            <FormGroup className="mb-3">
                <FormLabel htmlFor={'amount'}>Total</FormLabel>
                
                <CurrencyInput
                    className="form-control" id={'amount'} name="amount"
                    placeholder="Please enter a number"
                    defaultValue={this.state.amount}
                    decimalsLimit={2}
                    intlConfig= {{locale: 'id-ID', currency: 'IDR'}}
                    onValueChange={(value, name, values) => this.setState({amount:value})} required
                    />
            </FormGroup>
                    
                    <Button type="submit" id="update-btn">{this.state.edit?"Perbarui Transaksi":"Catat Transaksi"}</Button>
                    {' '}
                    
                    <Button variant="secondary" onClick={this.props.handleClose}>Batal</Button>
                    
                
            </Form>
        )
    }
}
//connect(mapStateToProps, mapDispatchToProps)(CreateAssetForm);


class TransferForm extends Transactions{
    bookId = JSON.parse(localStorage.bookId);
    data = JSON.parse(localStorage.data);
    constructor(props){
        super(props);
		this.state ={
            Id:props.data.transaction_id,
            assetIdFrom:'',
            assetIdTo:'',
            bookId:this.bookId,
            show:props.show,
			desc: props.data.desc,
            transactionType: props.data.transactionType,
            amount: props.data.amount,
            categoryId: props.data.category_id,
            validated: false,
            active:props.data.active,
            edit:props.update,
            assets:props.assets,
            categories:props.categories,
            transactionDate:new Date(),
            user_id: this.data.user_id
		}
    }
    componentDidMount(){
        //this.setState({show:props.show});
        //console.log(this.state.name)
        //var bookId = JSON.parse(localStorage.bookId);
        
        //this.props.getAssets(bookId)
        if(typeof(this.props.transactionType)!=="undefined"){
            this.setState({transactionType:this.props.transactionType})
        }
    }
    
    getAssetsFrom = () => {
        var assets = this.state.assets
        
        //if(this.state.transactionType=="income"){
            assets = assets.filter(asset => (asset.type ==='1' && asset.active ==='1' && asset.category ==='1'))
            //this.setState({assetId:(assets[0].asset_id)})
        //}else{
       //     assets = assets.filter(asset => (asset.type ==='2'))
        //}
        //console.log(this.state.transactionType)
        return(
            <FormGroup className="mb-3">
                <FormLabel htmlFor={'akun'}>Asal</FormLabel>
                    <Form.Select aria-label="select Type" id="akun" value={this.state.assetIdFrom} onChange={ (e) => this.setState({assetIdFrom:(e.target.value)}) } required >
                        <option></option>
                    {assets.map((item,index) => (
                        <option key={index} value={item.asset_id}>{item.name}</option>
                    ))}
                    </Form.Select>
                </FormGroup>
        )
    }

    getAssetsTo = () => {
        var assets = this.state.assets
        
        //if(this.state.transactionType=="income"){
            assets = assets.filter(asset => (asset.type ==='1' && asset.active ==='1' && asset.category ==='1'))
            //this.setState({assetId:(assets[0].asset_id)})
        //}else{
       //     assets = assets.filter(asset => (asset.type ==='2'))
        //}
        //console.log(this.state.transactionType)
        return(
            <FormGroup className="mb-3">
                <FormLabel htmlFor={'akun'}>Tujuan</FormLabel>
                    <Form.Select aria-label="select Type" id="akun" value={this.state.assetIdTo} onChange={ (e) => this.setState({assetIdTo:(e.target.value)}) } required >
                        <option></option>
                    {assets.map((item,index) => (
                        <option key={index} value={item.asset_id}>{item.name}</option>
                    ))}
                    </Form.Select>
                </FormGroup>
        )
    }
    
    
    handleChangeDate = (date, event) => {
        //const d = new Date(date).toLocaleDateString('id-ID');
      //console.log(d);
      //setDate(d);
        //console.log(date);
        this.setState({transactionDate:date})
        //console.log(date);
      };
      //console.log(data.user_email)
      formatDate(date) {
        var d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();
    
        if (month.length < 2) 
            month = '0' + month;
        if (day.length < 2) 
            day = '0' + day;
    
        return [year, month, day].join('-');
    }

    saveTransaction = async (e) => {
        e.preventDefault();
        const form = e.currentTarget;
        //console.log(form)
        if (form.checkValidity() === false) {
          //e.preventDefault();
          e.stopPropagation();
        }
    
        this.setState({validated:true});
        
    
        this.props.createTransaction({
            assetId:this.state.assetIdFrom,
            bookId:this.state.bookId,
            desc:"Transfer Asset",
            categoryId:0,
            amount:-this.state.amount,
            transactionDate:new Date(),

        }).then((result)=>{
            //console.log(result)
            if(result){
                this.props.createTransaction({
                    assetId:this.state.assetIdTo,
                    bookId:this.state.bookId,
                    desc:"Transfer Asset",
                    categoryId:0,
                    amount:this.state.amount,
                    transactionDate:new Date(),

                }).then((result)=>{
                    //console.log(result)
                    if(result){
                        this.props.handleClose()
                    }
                })
            }
        })
    
        //console.log(response.status)
        //navigate("/books")
    }
    
    //console.log(props.handleClose)
    render(){
        return(
        <Form id="createTransactionForm" validated={this.state.validated}  onSubmit={ this.saveTransaction }>
            <h1>{this.state.transactionType}</h1>
            <this.getAssetsFrom />

            <this.getAssetsTo />

           

            <FormGroup className="mb-3">
                <FormLabel htmlFor={'date'}>Tanggal Transaksi</FormLabel>
                <Row>
                <Col>
                <DatePicker className="form-control"
                selected={this.state.transactionDate}
                onChange={(date)=>this.handleChangeDate(date)}
                dateFormat="dd/MMM/yyyy"
                maxDate={new Date()}
                calendarClassName="form-control"
                peekNextMonth
                showMonthDropdown
                showYearDropdown
                dropdownMode="select"
                />
                </Col></Row>
            </FormGroup>

            <FormGroup className="mb-3">
                <FormLabel htmlFor={'desc'}>Description</FormLabel>
                <Form.Control as={'textarea'} rows={3}  className="form-control" id={'desc'} name="desc" value={ this.state.desc } onChange={ (e) => this.setState({desc:(e.target.value)}) } required  />
            </FormGroup>

            
            <FormGroup className="mb-3">
                <FormLabel htmlFor={'amount'}>Total</FormLabel>
                
                <CurrencyInput
                    className="form-control" id={'amount'} name="amount"
                    placeholder="Please enter a number"
                    defaultValue={this.state.amount}
                    decimalsLimit={2}
                    intlConfig= {{locale: 'id-ID', currency: 'IDR'}}
                    onValueChange={(value, name, values) => this.setState({amount:value})} required
                    />
            </FormGroup>
                    
                    <Button type="submit" id="update-btn">Catat Transaksi Transfer</Button>
                    {' '}
                    
                    <Button variant="secondary" onClick={this.props.handleClose}>Batal</Button>
                    
                
            </Form>
        )
    }
}