//import AsyncStorage from '@react-native-community/async-storage';
//import auth from '@react-native-firebase/auth';
//import database from '@react-native-firebase/database';
import axios from "axios";
import {SET_USER, SET_TOKEN} from "../actions/actionTypes"
import {getBook} from "./book"
import { redirect, useNavigate, useHistory } from "react-router-dom";

//const UUID = require("uuid-v4");


/*
export const getUser = (id)=>{
    return((dispatch)=>{
        //console.log("b")
        const promise = new Promise(async(resolve,reject)=>{
            try {
                const user = await axios.get(global.backend+'users/'+id)
                //console.log(data.user_id)
                if(Object.keys(user.data).length===0 || user==null){
                    resolve('/user');
                }else{
                    dispatch(setUser(user.data[0]))
                }
                for (var user_data in user.data[0]) {
                    //console.log(user_data+":"+user.data[0][user_data]);
                    if(((!user.data[0][user_data] || user.data[0][user_data] == "") && user_data!="default_book_id") && user_data!=='user_activation_key'){
                        //empty = true;
                        //console.log(user_data+' is empty');
                        resolve('/user');
                    }
                    if(user.data[0]["default_book_id"]==""||user.data[0]["default_book_id"]== null){
                        resolve('/books')
                    }else{
                        //console.log("here")
                        //dispatch(getBook(id))
                        //resolve('/')
                    }
                    //localStorage.setItem("bookId",user.data[0]["default_book_id"])
                    
                    //return Promise.resolve()
                }
                dispatch(getBook(id))
                localStorage.setItem("bookId",user.data[0]["default_book_id"])
                //console.log(user.data[0])
                
                resolve('/')
            } catch(e) {
                console.log(e)
            }
        })
        return promise
        .then(val=>{
            if(val){
                //dispatch(setUser(val))
                console.log(val)
                return val
                //return (val)

            }
            
        })

    })
}
*/
export const getUser = (id) => {
    return((dispatch)=>{
        //console.log("b")
        const promise = new Promise(async(resolve,reject)=>{
            try {
                const user = await axios.get(global.backend+'users/'+id)
                //console.log(user)
                if(Object.keys(user.data).length!==0){
                    dispatch(setUser(user.data[0]));
                    //console.log(books)
                    resolve(user);
                }else{
                    resolve("new")
                }
            } catch(e) {
                console.log(e)
            }
        })
        return promise
        .then(val=>{
            if(val){
                //dispatch(setUser(val))
                //console.log(val)
                return val
                //return (val)

            }
            
        })

    })
}
/*
export const updateUser = async (user) => {
    //e.preventDefault();
    let response = await axios.post(global.backend+'users/update/'+this.state.user_id, {
        user_id: this.state.user_id,
        email: this.state.email,
        name: this.state.name,
        phone: this.state.phone,
        dob: this.formatDate(this.state.dob),
        profession: this.state.profession,
        sex: this.state.sex,
        location: this.state.location,
        marriage: this.state.marriage,
        smoking: this.state.smoking,
        bio: this.state.bio
    
    })
    this.setState({submitted:true})
    //console.log(response)
    //navigate("/")
}
*/
const formatDate=(date)=> {
    var d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

    if (month.length < 2) 
        month = '0' + month;
    if (day.length < 2) 
        day = '0' + day;

    return [year, month, day].join('-');
}

export const createUser = (data) => {
    //console.log(data)
    return((dispatch)=>{
        const promise = new Promise(async(resolve,reject)=>{
            try{
                var response = await axios.post(global.backend+'users', {
                    user_id: data.user_id,
                    email: data.email,
                    name: data.name,
                    phone: data.phone,
                    dob: formatDate(data.dob),
                    profession: data.profession,
                    sex: data.sex,
                    location: data.location,
                    marriage: data.marriage,
                    smoking: data.smoking,
                    bio: data.bio
                    })
            
            if(response.status=="200"||response.status=="201"){
                //props.handleClose()
                resolve(true)
            }else{
                resolve(false)
            }
            //console.log(response.status)
            }catch(e){
                console.log(e)
                //reject("error")
            }
    //navigate("/books")
        })
        return promise
        .then(val=>{
            dispatch(getUser(data.user_id))
            return val
        })
    })
}

export const updateUser = (data) => {
    //console.log(data)
    return((dispatch)=>{
        const promise = new Promise(async(resolve,reject)=>{
            try{
                var response = await axios.post(global.backend+'users/update/'+data.user_id, {
                    user_id: data.user_id,
                    email: data.email,
                    name: data.name,
                    phone: data.phone,
                    dob: formatDate(data.dob),
                    profession: data.profession,
                    sex: data.sex,
                    location: data.location,
                    marriage: data.marriage,
                    smoking: data.smoking,
                    bio: data.bio
                    })
            
            if(response.status=="200"||response.status=="201"){
                //props.handleClose()
                resolve(true)
            }else{
                resolve(false)
            }
            //console.log(response.status)
            }catch(e){
                console.log(e)
                //reject("error")
            }
    //navigate("/books")
        })
        return promise
        .then(val=>{
            dispatch(getUser(data.user_id))
            return val
        })
    })
}

export const setToken =(item)=>{
    return{
        type: SET_TOKEN,
        item: item,
    }
}
export const setUser = (item) => {
    //AsyncStorage.setItem("grow:auth:user",user.uid);
    //console.log(user)
    //addUser(user)
    //console.log(item)
    return{
        type: SET_USER,
        item: item,
    }
}